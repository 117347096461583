var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"af748f4f289d6c36a8b2b67136ff134282234c67"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const dsn =
  process.env.NODE_ENV !== "development" && process.env.VERCEL === "1"
    ? process.env.NEXT_PUBLIC_SENTRY_DSN
    : undefined;

Sentry.init({
  dsn,

  environment: process.env.VERCEL_ENV,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
